.app {
  width: 50%;
  min-height: 500px;
  margin: 10vh auto 0;
  padding: 20px;
}

.app > h1 {
  margin-top: 0;
  font-size: 50px;
}

input, textarea {
  color: #000;
  border: 1px solid #728c73;
  padding: 8px 20px;
  font-size: 18px;
  line-height: 18px;
  display: block;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  color: #c7d1c7;
}

.app .admin-container {
  background: #fff;
  border-radius: 10px;
  width: calc(100% - 48px);
  margin-bottom: 64px;
  padding: 24px;
}

.app .admin-container h2 {
  margin-top: 0;
}

.app button.primary {
  color: #fff;
  cursor: pointer;
  background: #728c73;
  border: 2px solid #728c73;
  padding: 8px 24px;
  font-size: 16px;
}

.app button.secondary {
  color: #728c73;
  cursor: pointer;
  background: #fff;
  border: 2px solid #728c73;
  padding: 8px 24px;
  font-size: 16px;
}

.app .admin-actions {
  text-align: right;
  margin-top: 32px;
}

/*# sourceMappingURL=App.css.map */
