.app {
    width: 50%;
    margin: 10vh auto 0 auto;
    padding: 20px;
    min-height: 500px
}
.app > h1 {
    margin-top: 0;
    font-size: 50px;
}

input, textarea {
    line-height: 18px;
    font-size: 18px;
    padding: 8px 20px;
    color: black;
    border: 1px solid #728C73;
    display: block;
}
textarea {
    resize: vertical;
}
input::placeholder,
textarea::placeholder {
    color: #c7d1c7;
}

.app .admin-container {
    background: #FFF;
    border-radius: 10px;
    width: calc(100% - 48px);
    margin-bottom: 64px;
    padding: 24px;
}
.app .admin-container h2 {
    margin-top: 0;
}
.app button.primary {
    background: #728C73;
    border: 2px solid #728C73;
    color: #FFF;
    font-size: 16px;
    padding: 8px 24px;
    cursor: pointer;
}
.app button.secondary {
    background: #FFF;
    border: 2px solid #728C73;
    color: #728C73;
    font-size: 16px;
    padding: 8px 24px;
    cursor: pointer;
}
.app .admin-actions {
    margin-top: 32px;
    text-align: right;
}
